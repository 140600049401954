// // staging
// export default {  "projectId": "mishe-staging-39dac",  "appId": "1:97385691144:web:258e1721952c9abf54f9bd",  "databaseURL": "https://mishe-staging-39dac.firebaseio.com",  "storageBucket": "mishe-staging-39dac.appspot.com",  "locationId": "us-central",  "apiKey": "AIzaSyBOTTnaTYTcp5rfhvnPQQl_Wv6eVc-7OnQ",  "authDomain": "mishe-staging-39dac.firebaseapp.com",  "messagingSenderId": "97385691144"}

// production
export default {
  "apiKey": "AIzaSyCFLPDCIkI8cq_5xCS-Paeb4m5rdQegmdo",
  "authDomain": "mishe-production.firebaseapp.com",
  "projectId": "mishe-production",
  "storageBucket": "mishe-production.appspot.com",
  "messagingSenderId": "674544449896",
  "appId": "1:674544449896:web:1de94f712d4ca3563bfedd"
}
